
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import myTemplate from './components/myTemplate/index.vue';
import templateMarket from './components/templateMarket/index.vue';

@Component({
  name: 'setting',
  components: {
    Submenu,
    myTemplate,
    templateMarket,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '',
      list: [
        {name: '我的模板', active: true},
        {name: '模板市场', active: false},
      ],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '我的模板';
  }
}
