
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import {
  sharedTemplates,
  sharedTemplateRequests,
  uITemplates,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {SharedTemplate} from '@/externals/MaxCI-SharedTemplate-v1';
import {UITemplate} from '@/externals/MaxCI-UITemplate-v1';

@Component({
  name: 'templateMarketDetail',
  components: {
    multiSelect,
  },
})
export default class extends Vue {
  private percentage = 10;
  private dialogVisible = false;
  private detailId: ObjectId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private detailMsg: SharedTemplate | null = null;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    this.detailMsg =
      (
        await sharedTemplates.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            );
            return match;
          }),
        )
      ).find(() => true) ?? null;
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //查看大图
  private checkimg(url: string) {
    this.$alert(
      '<img style="max-width:100%;max-height:700px;" src="' + url + '">',
      '图片',
      {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        callback: action => {
          console.log(action);
        },
      },
    );
  }
  //判断名称是否重复
  private checkName(data: Array<UITemplate>, index: number): string {
    let num = 0;
    let name = '';
    if (index > 0) {
      name = (this.detailMsg?.spec.name ?? '') + '(' + index + ')';
    } else {
      name = this.detailMsg?.spec.name ?? '';
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].spec.name === name) {
        num++;
        break;
      }
    }
    if (num === 0) {
      return name;
    } else {
      return this.checkName(data, index + 1);
    }
  }
  //下载模板
  private async downBtn() {
    const uITemplate = await uITemplates.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        );
        return match;
      }),
    );
    const name = this.checkName(uITemplate, 0);
    sharedTemplateRequests
      .create(
        [
          {
            spec: {
              type: '复制',
              name: name,
              sharedTemplateId: this.detailId,
              applicationId: this.applicationId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res[0].status?.phase === '成功') {
          this.dialogVisible = true;
          this.percentage = 10;
          const timer = setInterval(() => {
            if (this.percentage === 100) {
              clearInterval(timer);
              this.dialogVisible = false;
              this.$message.success('下载成功');
            } else {
              this.percentage = this.percentage + 10;
            }
          }, 100);
        } else {
          this.$message.error(res[0].status?.conditions[0].message ?? '');
        }
      });
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
