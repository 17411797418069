
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import {uITemplates, channels} from '../../../../resources';
import {EJSON, ObjectId} from 'bson';
import {Form} from 'element-ui';
import {UITemplate, UITemplateSpec} from '@/externals/MaxCI-UITemplate-v1';
import Url from 'url';
import {saveAs} from 'file-saver';

interface MyTemplate extends UITemplate {
  oldName: string;
}
@Component({
  name: 'myTemplate',
  components: {
    multiSelect,
  },
})
export default class extends Vue {
  private dialogDel = false; //删除弹窗
  private operateId: ObjectId | null = null;
  private submitFlag = false;
  private formData = {name: '', dependencies: []};
  private rules = {
    name: [{required: true, message: '请输入模板名称', trigger: 'blur'}],
  };
  private dialogAdd = false; //新建模板弹窗
  private tabActive = '我创建的';
  private listData: Array<MyTemplate> = [];
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  created() {
    this.updateList();
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //导入
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async importBtn(file: any) {
    try {
      let spec = JSON.parse(
        (await this.getImportData(file)) as string,
      ).template;
      for (let i = 0; ; i++) {
        const newName = i === 0 ? spec.name : spec.name + '(' + i + ')';
        if (!this.listData.find(item => item.spec.name === newName)) {
          spec.name = newName;
          break;
        }
      }
      console.log(spec);
      const uITemplate = await uITemplates.create([
        {
          spec: {
            ...spec,
            applicationId: this.applicationId,
          },
        },
      ]);
      if (uITemplate.length > 0) {
        this.$message.success('导入成功');
        this.updateList();
      } else {
        this.$message.error('导入失败');
      }
    } catch (e) {
      console.log(e);
      this.$message.error('导入失败');
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async getImportData(file: any) {
    return new Promise(function (resolve) {
      const reader = new FileReader();
      reader.readAsText(file.raw);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result);
        }
      };
    });
  }
  //导出
  private exportBtn(item: MyTemplate) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const spec = item.spec as any;
      delete spec.applicationId;
      const blob = new Blob([EJSON.stringify({template: spec})], {
        type: 'text/plain;charset=utf-8',
      });
      saveAs(blob, `模板-${spec.name}.json`);
    } catch (e) {
      console.log(e);
    }
  }
  //获取页面缩略图
  private getImage(spec: UITemplateSpec) {
    if (typeof spec.index === 'string') {
      const url = Url.parse(spec.index ?? '', true);
      return spec.pages[url.query.page as string] &&
        spec.pages[url.query.page as string].image
        ? spec.pages[url.query.page as string].image
        : '';
    }
  }
  //编辑
  private editBtn(id: ObjectId) {
    this.$router.push({
      path:
        '/application/' +
        this.applicationId.toHexString() +
        '/template/' +
        id.toHexString(),
    });
  }
  //删除提示
  private async delPopUps(id: ObjectId) {
    const channel = await channels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('templateId'),
          e => e.$eq(id),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    );
    if (channel.length > 0) {
      this.$message.error('该模板已被渠道绑定，不能删除！');
    } else {
      this.operateId = id;
      this.dialogDel = true;
    }
  }
  //删除
  private async delSumbit() {
    try {
      if (this.operateId) {
        const roleMapping = await uITemplates.delete(filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        );
        if (roleMapping) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //切换分类
  private changeTab() {
    this.updateList();
  }
  //修改名称
  private changeName(index: number) {
    //判断名字是否为空
    if (this.listData[index].spec.name) {
      uITemplates
        .update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.listData[index]._id),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update.$set(s =>
              s(f => f('spec')('name'), this.listData[index].spec.name),
            ),
        )
        .then(res => {
          if (res.length > 0) {
            this.listData[index].oldName = this.listData[index].spec.name;
          }
        });
    } else {
      this.listData[index].spec.name = this.listData[index].oldName;
    }
  }
  //查询列表
  private async updateList() {
    try {
      this.listData = (
        await uITemplates.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            if (this.tabActive === '我创建的') {
              match(
                f => f('spec')('fromMarket'),
                e => e.$eq(false),
              );
            } else {
              match(
                f => f('spec')('fromMarket'),
                e => e.$eq(true),
              );
            }
            return match;
          }),
        )
      ).map(v => {
        return {
          ...v,
          oldName: v.spec.name,
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //新增保存
  private addSumbit() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        if (this.formData.dependencies.length > 0) {
          try {
            this.submitFlag = true;
            uITemplates
              .create([
                {
                  spec: {
                    name: this.formData.name,
                    dependencies: this.formData.dependencies,
                    fromMarket: false,
                    index:
                      'wx://forward.self/page?page=默认&subpage=默认&params={}',
                    pages: {
                      默认: {
                        setting: {},
                        subPages: [
                          {
                            name: '默认',
                            tabItem: {
                              text: '默认',
                              images: [
                                '600914214bcfb3703c64eee2',
                                '600914534bcfb3008464eee3',
                              ],
                              color: '#333333',
                              selectColor: '#11A983',
                              fontSize: 14,
                              selectFontSize: 14,
                            },
                            setting: {},
                            components: [],
                          },
                        ],
                        category: '默认',
                      },
                    },
                    categories: ['默认'],
                    applicationId: this.applicationId,
                  },
                },
              ])
              .then(res => {
                if (res.length > 0) {
                  this.$message.success('保存成功');
                  this.dialogAdd = false;
                  this.updateList();
                }
              });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (e: any) {
            this.$message.error('网络异常，请稍后重试');
          } finally {
            this.submitFlag = false;
          }
        } else {
          this.$message.error('请至少选择一个功能');
        }
      } else {
        return false;
      }
    });
  }
  //新建模板弹窗
  private addPopUps() {
    this.dialogAdd = true;
    this.formData.name = '';
    this.formData.dependencies = [];
  }
  //计算文本框的宽度
  private getWidth(str: string) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.font = 'bold 16px 微软雅黑';
    const text = ctx.measureText(str);
    return text.width ? text.width : 5;
  }
}
