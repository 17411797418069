
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import {sharedTemplates} from '../../../../resources';
import {ObjectId} from 'bson';
import {SharedTemplate} from '@/externals/MaxCI-SharedTemplate-v1';

@Component({
  name: 'templateMarketList',
  components: {
    multiSelect,
  },
})
export default class extends Vue {
  private total = 0;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private operateId: ObjectId | null = null;
  private list: Array<SharedTemplate> = [];
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  created() {
    this.updateList();
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //编辑
  private editBtn(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //查询列表
  private async updateList() {
    try {
      const list = await sharedTemplates.find(stage =>
        stage.$facet(facet =>
          facet('table', tableStage =>
            tableStage
              .$skip((this.listParams.page - 1) * this.listParams.limit)
              .$limit(this.listParams.limit),
          )('count', countStage => countStage.$count('count')),
        ),
      );
      this.list = list[0].table;
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
}
